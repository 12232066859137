// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amber-1-de-js": () => import("./../../../src/pages/amber1.de.js" /* webpackChunkName: "component---src-pages-amber-1-de-js" */),
  "component---src-pages-amber-1-en-js": () => import("./../../../src/pages/amber1.en.js" /* webpackChunkName: "component---src-pages-amber-1-en-js" */),
  "component---src-pages-amber-1-pl-js": () => import("./../../../src/pages/amber1.pl.js" /* webpackChunkName: "component---src-pages-amber-1-pl-js" */),
  "component---src-pages-amber-2-de-js": () => import("./../../../src/pages/amber2.de.js" /* webpackChunkName: "component---src-pages-amber-2-de-js" */),
  "component---src-pages-amber-2-en-js": () => import("./../../../src/pages/amber2.en.js" /* webpackChunkName: "component---src-pages-amber-2-en-js" */),
  "component---src-pages-amber-2-pl-js": () => import("./../../../src/pages/amber2.pl.js" /* webpackChunkName: "component---src-pages-amber-2-pl-js" */),
  "component---src-pages-amber-3-de-js": () => import("./../../../src/pages/amber3.de.js" /* webpackChunkName: "component---src-pages-amber-3-de-js" */),
  "component---src-pages-amber-3-en-js": () => import("./../../../src/pages/amber3.en.js" /* webpackChunkName: "component---src-pages-amber-3-en-js" */),
  "component---src-pages-amber-3-pl-js": () => import("./../../../src/pages/amber3.pl.js" /* webpackChunkName: "component---src-pages-amber-3-pl-js" */),
  "component---src-pages-cennik-de-js": () => import("./../../../src/pages/cennik.de.js" /* webpackChunkName: "component---src-pages-cennik-de-js" */),
  "component---src-pages-cennik-en-js": () => import("./../../../src/pages/cennik.en.js" /* webpackChunkName: "component---src-pages-cennik-en-js" */),
  "component---src-pages-cennik-pl-js": () => import("./../../../src/pages/cennik.pl.js" /* webpackChunkName: "component---src-pages-cennik-pl-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-kontakt-de-js": () => import("./../../../src/pages/kontakt.de.js" /* webpackChunkName: "component---src-pages-kontakt-de-js" */),
  "component---src-pages-kontakt-en-js": () => import("./../../../src/pages/kontakt.en.js" /* webpackChunkName: "component---src-pages-kontakt-en-js" */),
  "component---src-pages-kontakt-pl-js": () => import("./../../../src/pages/kontakt.pl.js" /* webpackChunkName: "component---src-pages-kontakt-pl-js" */),
  "component---src-pages-oferta-de-js": () => import("./../../../src/pages/oferta.de.js" /* webpackChunkName: "component---src-pages-oferta-de-js" */),
  "component---src-pages-oferta-en-js": () => import("./../../../src/pages/oferta.en.js" /* webpackChunkName: "component---src-pages-oferta-en-js" */),
  "component---src-pages-oferta-pl-js": () => import("./../../../src/pages/oferta.pl.js" /* webpackChunkName: "component---src-pages-oferta-pl-js" */),
  "component---src-pages-ofertaspecjalna-de-js": () => import("./../../../src/pages/ofertaspecjalna.de.js" /* webpackChunkName: "component---src-pages-ofertaspecjalna-de-js" */),
  "component---src-pages-ofertaspecjalna-en-js": () => import("./../../../src/pages/ofertaspecjalna.en.js" /* webpackChunkName: "component---src-pages-ofertaspecjalna-en-js" */),
  "component---src-pages-ofertaspecjalna-pl-js": () => import("./../../../src/pages/ofertaspecjalna.pl.js" /* webpackChunkName: "component---src-pages-ofertaspecjalna-pl-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/politykaPrywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

